import React from 'react';
import { 
  Form,
  Button,
  ButtonToolbar,
  InputNumber,
  Schema,
  toaster,
  Message,
} from 'rsuite';
import {Navigate } from 'react-router-dom';
import {Amplify, API, graphqlOperation } from 'aws-amplify';
import awsconfig from '../aws-exports';
import { rsvp } from '../graphql/mutations';
import './index.css';
import './fireworks.css';
Amplify.configure(awsconfig);
const {StringType, NumberType} = Schema.Types;


const model = Schema.Model({
  firstName: StringType().isRequired('This field is required'),
  lastName: StringType().isRequired('This field is required'),
  headCount: NumberType("Please enter a valid number").range(1,10, 'Please enter a number from 1 to 10.'),
});

interface RSVPProps{
}

interface RSVPState{
  redirect: boolean,
  formValue: FormData,
  preredirect: boolean,
}

interface FormData{
  firstName: String,
  lastName: String,
  headCount: Number,
}

const RSVPStyle = {
  "background-color": "#5C94CE",
  "border-radius":"30px",
  "font-weight":"500",
  "color":"#FFFFFF",
  "font-size":"20px",
  "font-family": "gibbs,sans-serif",
};


class Rsvp extends React.Component<RSVPProps, RSVPState>{
  constructor(props: RSVPProps) {
    super(props);
    this.state = {
      redirect: false,
      preredirect: false,
      formValue: {
        firstName:'',
        lastName:'',
        headCount: 1,
      }
    };
  }

  setFormValue = (formValue: FormData) => {
    this.setState({
      formValue: formValue
    });
  }

  redirect = () => {
    this.setState({
      redirect: true,
    });
  }

  submit = async (valid: boolean) => {
    const { formValue } = this.state;
    if (valid){
      const rsvpInput = {
        firstName: formValue.firstName,
        lastName: formValue.lastName,
        headCount: formValue.headCount,
      };
      try {
        const results = await API.graphql(graphqlOperation(rsvp, {Content: rsvpInput}));
        console.log(results);
        toaster.push(
          <Message type="success" closable>
            You have successfully RSVP'd. Redirecting to home page...
          </Message>
        );
        this.setState({
          preredirect: true,
        });
        setTimeout(this.redirect, 6000);
      } catch (err){
        const key = await toaster.push(
          <Message type="error" closable>
            Sorry there was an error, please try again later.
          </Message>
        );
        if(key){
          setTimeout(function(){
            toaster.remove(key);
          }, 5000);
        }
      }
    }
  }

  fireworks = (preredirect: boolean) => {
    if(preredirect){
      return (
      <div className="pyro">
        <div className="before"></div>
        <div className="after"></div>
      </div>
      );
    } else {
      return (<div/>)
    }
  }

  render() {
    const { formValue, redirect, preredirect } = this.state;

    if (redirect) {
      return <Navigate  to='/'/>
    }

    return(
      <div className='rsvpBody'>
        {this.fireworks(preredirect)}
        <div className="rsvpTitle">
            RSVP
        </div>
        <div className="formHolder">
          <Form 
          model={model}
          formValue={formValue}
          onChange={formValue=> this.setFormValue(formValue as FormData)}
          onSubmit={this.submit}
          fluid>
            <Form.Group controlId='firstName'>
              <Form.ControlLabel>First Name</Form.ControlLabel>
              <Form.Control name="firstName" />
              <Form.HelpText>Required</Form.HelpText>
            </Form.Group>
            <Form.Group controlId='lastName'>
              <Form.ControlLabel>Last Name</Form.ControlLabel>
              <Form.Control name="lastName" />
              <Form.HelpText>Required</Form.HelpText>
            </Form.Group>
            <Form.Group controlId='headCount'>
              <Form.ControlLabel>Guest Count</Form.ControlLabel>
              <Form.Control name="headCount" accepter={InputNumber} style={{width:"100%"}}/>
              <Form.HelpText>Number of people coming (including yourself)</Form.HelpText>
            </Form.Group>
            <Form.Group>
              <ButtonToolbar>
                <Button style={RSVPStyle} appearance="primary" type="submit">Submit</Button>
              </ButtonToolbar>
            </Form.Group>
          </Form>
        </div> 
      </div>
    );
  }
}

export default Rsvp;