import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { CustomProvider } from 'rsuite';
import App from '../home';
import Rsvp from '../rsvp';

const Routing = (
  <BrowserRouter>
    <CustomProvider theme='dark'>
    <div>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/rsvp" element={<Rsvp />} />
        <Route element={<App />} />
      </Routes>
    </div>
    </CustomProvider>
  </BrowserRouter>
);

export default Routing;