import banner from '../assets/SVG/Banner.svg';
import firework from '../assets/SVG/Firework.svg';
import ppls from '../assets/SVG/GrillingPatzers.svg';
import { Button } from 'rsuite';
import './index.css';

const RSVPStyle = {
  "background-color": "#E25C44",
  "border-radius":"30px",
  "font-weight":"500",
  "color":"#FFFFFF",
  "font-size":"20px",
  "font-family": "gibbs,sans-serif",
};

const CalendarStyle = {
  "background-color": "#fbc861",
  "border-radius":"30px",
  "font-weight":"500",
  "color":"#FFFFFF",
  "font-size":"20px",
  "font-family": "gibbs,sans-serif",
};

const DirectionsStyle = {
  "background-color": "#5C94CE",
  "border-radius":"30px",
  "font-weight":"500",
  "color":"#FFFFFF",
  "font-size":"20px",
  "display":"inline-block",
  "font-family": "gibbs,sans-serif",
}


function App() {
  return (
    <div className="App">
      <img src={banner} className="header" alt="banners" />
      <div className='firstSection'>
        <div className="leftFirstSection">
        <div className="combinedTitle">
          <img src={firework} className="firework" alt="firework"/>
          <div className="title">
            July 1st BBQ
          </div>
        </div>
        <div className="time">
          Saturday @ 4:30pm
        </div>
          <div className="partyDesc">
          Breakout your Old Navy t-shirts cause it's time to celebrate America's birthday! Join us on Saturday, July 1st @ 4:30pm for an afternoon of burgers, brews and lawn games. We will also have a bonfire with s'mores & sparklers later in the evening. The more the merrier, so feel free to invite your friends.
          </div>
          <div className='buttonGroup'>
            <Button style={RSVPStyle} className="rsvpButton" href='/rsvp'>RSVP</Button>
            <Button style={DirectionsStyle} className="directionsButton" href='https://goo.gl/maps/b2WDN76MtPDpx7x58'>Directions</Button>
          </div>
          <div className='buttonGroup2'>
            <div></div>
            <Button style={CalendarStyle} className="calendarButton" href='./myevents.ics'>Add to Calendar</Button>
          </div>
        </div>
        <div className='rightFirstSection'>
          <img src={ppls} className="peopleImg" alt="Two people at a bbq"/>
        </div>
      </div>
    </div>
  );
}

export default App;
